<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="info in infoList">
        <CToast
          :key="info.message"
          :show="true"
          :header="info.header"
          :color="info.color"
        >
          {{ info.message }}.
        </CToast>
      </template>
    </CToaster>
    <CRow>
      <CCol col="12" lg="12">
        <CCard>
          <CCardHeader> <strong>General</strong> Company </CCardHeader>
          <CCardBody>
            <CForm>
              <CInput label="Name" horizontal v-model="obj.name" />
              <CInput label="RegNo" horizontal v-model="obj.regNo" />
              <CInput label="Tagline" horizontal v-model="obj.tagLine" />
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label"> Address </CCol>
                <CCol sm="9">
                  <CTextarea
                    placeholder="Address..."
                    rows="5"
                    v-model="obj.address"
                  />
                </CCol>
              </CRow>

              <CInput label="Phone" horizontal v-model="obj.phone" />
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  Company Logo
                </CCol>
                <CCol sm="9">
                  <CImg :src="getImageUrl()" class="mb-2" thumbnail></CImg>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label"> </CCol>
                <CCol sm="9">
                  <WidgetsUploadImage :logoUrl="logoUrl" @uploaded="uploaded" />
                </CCol>
              </CRow>

              <CInput label="Bank Name" horizontal v-model="obj.bankName" />
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  Bank Address
                </CCol>
                <CCol sm="9">
                  <CTextarea
                    placeholder="Address..."
                    rows="5"
                    v-model="obj.bankAddress"
                  />
                </CCol>
              </CRow>

              <CInput
                label="Bank Account Name"
                horizontal
                v-model="obj.bankAccountName"
              />
              <CInput
                label="Bank Account No"
                horizontal
                v-model="obj.bankAccountNo"
              />
            </CForm>
          </CCardBody>
          <CCardFooter>
            <CButton
              class="ml-1"
              color="primary"
              @click="onUpdate"
              >Update</CButton
            >
            <CButton class="ml-1" color="secondary" @click="onBack"
              >Cancel</CButton
            >
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CompanyApi from "../../lib/companyApi";
import { validationMixin } from "vuelidate";
import WidgetsUploadImage from "../widgets/WidgetsUploadImage";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
  decimal,
  maxLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

export default {
  name: "CompanyEdit",
  components: {
    WidgetsUploadImage,
  },
  data() {
    return {
      infoList: [],
      api: new CompanyApi(),
      obj: {},
      logoUrl: "",
      uploadedFiles: [],
    };
  },
  mounted() {
    var self = this;
    self.resetObj();
  },
  validations: {
    obj: {
      LocationCode: {
        required,
      },
      LocationName: {
        required,
      },
      PhoneNo: {
        required,
      },
      Longitude: {
        required,
      },
      Latitude: {
        required,
      },
      cameraipaddress: {
        required,
      },
      rtuipaddress: {
        required,
      },
      CommandCenterIpAddress: {
        required,
      },

      DioIpAddress: {
        required,
      },
      LidarIpAddress: {
        required,
      },
      RouterIpAddress: {
        required,
      },
    },
  },
  computed: {
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },
  methods: {
    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },
    getImageUrl() {
      var url = this.removeTrailingSlash(apiUrl) + this.obj.logoUrl;
      console.log(url);
      return url;
    },

    uploaded(data) {
      this.uploadedFiles = data.uploadedFiles;
      this.obj.logoUrl = this.uploadedFiles[0].url;
    },
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    onBack() {
      var self = this;
      self.$router.push({
        path: `/admins/company`,
      });
    },
    onUpdate() {
      var self = this;
      console.log(self.obj);
      self.api
        .update(self.obj)
        .then((response) => {
          // console.log(response);

          self.toast("Success", "Data had been update", "success");

          self.$router.push({
            path: `/admins/companyEdit`,
          });
        })
        .catch(({ data }) => {
          self.toast("Error", data.errorMsg, "danger");
          // console.log(data);
        });
    },
    resetObj() {
      var self = this;
      this.api
        .getList()
        .then((response) => {
          self.obj = response.result[0];
          console.log(self.obj);
        })
        .catch((data) => {
          self.toast("Error", data, "danger");
        });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  },
};
</script>
