<template>
  <CRow>
    <CCol>
      <!-- UPLOAD -->
      <div class="dropbox">
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="handleFileChange"
          accept="image/*"
          class="input-file"
        />
        <!-- Display initial message -->
        <div v-if="isInitial && !isSaving && !isFailed">
          <p>Drag your file(s) here to begin<br />or click to browse</p>
        </div>

        <!-- Show progress while uploading -->
        <div v-if="isSaving" class="progress mt-3">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            :style="{ width: uploadProgress + '%' }"
            :aria-valuenow="uploadProgress"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ uploadProgress }}%
          </div>
        </div>
      </div>

      <!-- SUCCESS MESSAGE -->
      <div v-if="isSuccess && !isSaving" class="alert alert-success mt-3">
        <p>Upload successful! You can drag more files or click to browse to add more.</p>
        <button class="btn btn-link p-0" @click="reset">Upload more files</button>
      </div>

      <!-- FAILED -->
      <div v-if="isFailed" class="alert alert-danger mt-3" role="alert">
        <p>Upload failed. Please try again.</p>
        <pre>{{ uploadError }}</pre>
        <button class="btn btn-link p-0" @click="reset">Try again</button>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import FileUploadApi from "../../lib/fileUploadApi"; 

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "WidgetsUploadImage",
  data() {
    return {
      uploadedFiles: [], 
      uploadError: null,
      currentStatus: STATUS_INITIAL,
      uploadFieldName: "image",
      fileUploadApi: new FileUploadApi(),
      fileCount: 0, 
      uploadProgress: 0, 
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    handleFileChange(event) {
      const files = event.target.files;
      if (!files.length) return;

      this.fileCount = files.length;
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append(this.uploadFieldName, file, file.name);
      });

      this.uploadFiles(formData);
    },
    uploadFiles(formData) {
      this.currentStatus = STATUS_SAVING;
      this.uploadProgress = 0;

      this.fileUploadApi.uploadImages(formData, this.updateProgress)
        .then((response) => {
          // this.uploadedFiles = [...this.uploadedFiles, ...response.result]; 
          this.uploadedFiles = response.result;

          this.currentStatus = STATUS_SUCCESS;
          this.$emit("uploaded", { uploadedFiles: this.uploadedFiles });
        })
        .catch((error) => {
          this.uploadError = error.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    updateProgress(event) {
      if (event.lengthComputable) {
        this.uploadProgress = Math.round((event.loaded * 100) / event.total);
      }
    },
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadError = null;
      this.uploadProgress = 0;
      this.fileCount = 0;
      this.uploadedFiles = [];
    }
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox:focus-within {
  outline-color: blue;
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
}

.progress {
  width: 100%;
  height: 20px;
  margin-top: 10px;
}

.alert {
  margin-top: 20px;
}
</style>
